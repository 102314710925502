<template>
 <div class="bg-white shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
  <div class="px-4 mx-auto max-w-7xl 2xl:px-0">
   <form
    @submit.prevent="checkForm($t('elementCreatedSuccessfully', { element: $t('contact') }))"
    method="POST"
   >
    <div class="space-y-10">
     <div class="pt-5 pb-12 border-b border-gray-200">
      <div class="grid grid-cols-1 mt-5 gap-x-6 gap-y-8 sm:grid-cols-6">
       <div class="sm:col-span-3">
        <label for="company-name" class="block text-sm font-medium leading-6 text-gray-900">
         {{ $t("contactsForm.companyName") }}
        </label>
        <div class="mt-2">
         <input
          v-model="form.companyName"
          type="text"
          name="company-name"
          id="company-name"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
         />
        </div>
       </div>

       <div class="sm:col-span-3">
        <label for="id-code" class="flex text-sm font-medium leading-6 text-gray-900">
         {{ $t("contactsForm.identificationCode") }}
         <p class="ml-2 text-xs italic text-gray-700">({{ $t("idCodeFieldRequirement") }})</p>
        </label>
        <div class="mt-2">
         <input
          v-model="form.idCode"
          type="text"
          name="id-code"
          id="id-code"
          :required="!form.contactsNumber"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
         />
        </div>
       </div>

       <div class="sm:col-span-3">
        <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">
         {{ $t("contactsForm.firstName") }}
        </label>
        <div class="mt-2">
         <input
          v-model="form.fName"
          id="first-name"
          name="first-name"
          type="text"
          autocomplete="first-name"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
         />
        </div>
       </div>
       <div class="sm:col-span-3">
        <label for="last-name" class="block text-sm font-semibold leading-6 text-gray-900">
         {{ $t("contactsForm.lastName") }}*
        </label>
        <div class="mt-2">
         <input
          v-model="form.lName"
          type="text"
          name="last-name"
          id="last-name"
          autocomplete="given-name"
          required
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
         />
        </div>
       </div>

       <div class="col-span-full">
        <label for="contacts-number" class="block text-sm font-medium leading-6 text-gray-900">
         {{ $t("callingNumbers") }}
        </label>
        <div class="mt-2">
         <div v-if="this.errorContact.length" class="text-xs text-red-500">
          <ul>
           <li v-for="error in errorContact" :key="error">
            {{ error }}
           </li>
          </ul>
         </div>
         <textarea
          v-model="form.contactsNumber"
          rows="2"
          name="contacts-number"
          id="contacts-number"
          :class="[
           errorContact.length
            ? 'ring-red-300 placeholder:text-red-400 focus:ring-2 focus:ring-inset focus:ring-red-600'
            : 'ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 text-gray-900',
           'block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset sm:text-sm sm:leading-6',
          ]"
         />
        </div>
       </div>
      </div>
     </div>

     <div class="pb-12 border-b border-gray-900/10">
      <fieldset>
       <div class="flex">
        <legend class="sr-only">Options</legend>
        <div class="relative flex items-center">
         <div class="flex items-center h-5">
          <input
           v-model="form.vip"
           value="1"
           id="comments"
           aria-describedby="comments-description"
           name="comments"
           type="checkbox"
           class="w-4 h-4 border-gray-300 rounded focus:encom_border_input"
          />
         </div>
         <div class="ml-3 text-sm">
          <label for="comments" class="font-medium text-gray-700">
           {{ $t("contactsForm.vip") }}
          </label>
          <span id="comments-description" class="text-gray-500"
           ><span class="sr-only">VIP </span></span
          >
         </div>
        </div>
        <div class="relative flex items-center ml-20">
         <div class="flex items-center h-5">
          <input
           v-model="form.onCall"
           value="1"
           id="candidates"
           aria-describedby="candidates-description"
           name="candidates"
           type="checkbox"
           class="w-4 h-4 border-gray-300 rounded focus:encom_border_input"
          />
         </div>
         <div class="ml-3 text-sm">
          <label for="candidates" class="font-medium text-gray-700">{{
           $t("contactsForm.onCall")
          }}</label>
          <span id="candidates-description" class="text-gray-500"
           ><span class="sr-only">Under on-call contract </span></span
          >
         </div>
        </div>
       </div>
      </fieldset>
     </div>
     <div class="pb-12 border-b border-gray-200">
      <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
       <div class="sm:col-span-4">
        <label for="dest-1" class="block text-sm font-semibold text-gray-700 sm:mt-px sm:pt-2">
         Destination 1*
        </label>
        <div class="mt-2 sm:col-span-2">
         <div class="relative">
          <input
           v-model="form.dest1"
           type="text"
           name="dest-1"
           id="dest-1"
           required
           :class="[
            errorDest1.length
             ? 'ring-red-300 placeholder:text-red-400 focus:ring-2 focus:ring-inset focus:ring-red-600'
             : 'ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 text-gray-900',
            'block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset sm:text-sm sm:leading-6',
           ]"
          />
          <div
           v-if="this.errorDest1.length"
           class="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none"
          >
           <ExclamationCircleIcon class="w-5 h-5 text-red-500" aria-hidden="true" />
          </div>
         </div>
         <div v-if="this.errorDest1.length">
          <p v-for="error in errorDest1" :key="error" class="mt-1 ml-1 text-sm text-red-600">
           {{ error }}
          </p>
         </div>
        </div>
       </div>
       <div class="sm:col-span-2 sm:col-start-1">
        <label for="dest-2" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
         Destination 2
        </label>
        <div class="mt-2 sm:col-span-2">
         <div class="relative">
          <input
           v-model="form.dest2"
           type="text"
           name="dest-2"
           id="dest-2"
           :class="[
            errorDest2.length
             ? 'ring-red-300 placeholder:text-red-400 focus:ring-2 focus:ring-inset focus:ring-red-600'
             : 'ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 text-gray-900',
            'block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset sm:text-sm sm:leading-6',
           ]"
          />
          <div
           v-if="this.errorDest2.length"
           class="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none"
          >
           <ExclamationCircleIcon class="w-5 h-5 text-red-500" aria-hidden="true" />
          </div>
         </div>
         <div v-if="this.errorDest2.length">
          <p v-for="error in errorDest2" :key="error" class="mt-1 ml-1 text-sm text-red-600">
           {{ error }}
          </p>
         </div>
        </div>
       </div>
       <div class="sm:col-span-2">
        <label for="dest-3" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
         Destination 3
        </label>
        <div class="mt-2 sm:col-span-2">
         <div class="relative">
          <input
           v-model="form.dest3"
           type="text"
           name="dest-3"
           id="dest-3"
           :class="[
            errorDest3.length
             ? 'ring-red-300 placeholder:text-red-400 focus:ring-2 focus:ring-inset focus:ring-red-600'
             : 'ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 text-gray-900',
            'block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset sm:text-sm sm:leading-6',
           ]"
          />
          <div
           v-if="this.errorDest3.length"
           class="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none"
          >
           <ExclamationCircleIcon class="w-5 h-5 text-red-500" aria-hidden="true" />
          </div>
         </div>
         <div v-if="this.errorDest3.length">
          <p v-for="error in errorDest3" :key="error" class="mt-1 ml-1 text-sm text-red-600">
           {{ error }}
          </p>
         </div>
        </div>
       </div>
       <div class="sm:col-span-2">
        <label for="dest-4" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
         Destination 4
        </label>
        <div class="mt-2 sm:col-span-2">
         <div class="relative">
          <input
           v-model="form.dest4"
           type="text"
           name="dest-4"
           id="dest-4"
           :class="[
            errorDest4.length
             ? 'ring-red-300 placeholder:text-red-400 focus:ring-2 focus:ring-inset focus:ring-red-600'
             : 'ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 text-gray-900',
            'block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset sm:text-sm sm:leading-6',
           ]"
          />
          <div
           v-if="this.errorDest4.length"
           class="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none"
          >
           <ExclamationCircleIcon class="w-5 h-5 text-red-500" aria-hidden="true" />
          </div>
         </div>
         <div v-if="this.errorDest4.length">
          <p v-for="error in errorDest4" :key="error" class="mt-1 ml-1 text-sm text-red-600">
           {{ error }}
          </p>
         </div>
        </div>
       </div>
      </div>
     </div>
    </div>

    <div class="flex items-center justify-end pb-12 mt-6 gap-x-6">
     <button
      type="button"
      @click="this.$emit('closeForm')"
      class="px-3 py-2 text-sm font-semibold text-gray-900 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
     >
      {{ $t("ticket.cancel") }}
     </button>
     <button
      type="submit"
      class="px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm body__table--header"
     >
      {{ $t("ticket.send") }}
     </button>
    </div>
   </form>
  </div>
 </div>
</template>

<script>
import axios from "axios";
import { ExclamationCircleIcon } from "@heroicons/vue/solid";
const account = localStorage.getItem("account");

export default {
 name: "ContactForm",
 components: { ExclamationCircleIcon },
 data() {
  return {
   account,
   showMessage: false,
   messageToShow: "",
   form: {
    companyName: "",
    idCode: "",
    fName: "",
    lName: "",
    contactsNumber: "",
    vip: 0,
    onCall: 0,
    dest1: "",
    dest2: "",
    dest3: "",
    dest4: "",
   },
   errorContact: [],
   errorDest1: [],
   errorDest2: [],
   errorDest3: [],
   errorDest4: [],
  };
 },
 methods: {
  checkForm(msg) {
   let contactsErrMsg = this.$t("contactsErrMsg");
   let destErrMsg = this.$t("destErrMsg");

   this.errorContact = [];
   this.errorDest1 = [];
   this.errorDest2 = [];
   this.errorDest3 = [];
   this.errorDest4 = [];

   if (
    this.validateContacts(this.form.contactsNumber) &&
    this.validateDest(this.form.dest1) &&
    this.validateDest(this.form.dest2) &&
    this.validateDest(this.form.dest3) &&
    this.validateDest(this.form.dest4)
   ) {
    this.onSubmit(msg);
   } else if (!this.validateContacts(this.form.contactsNumber)) {
    this.errorContact.push(contactsErrMsg);
   } else if (!this.validateDest(this.form.dest1)) {
    this.errorDest1.push(contactsErrMsg);
   } else if (!this.validateDest(this.form.dest2)) {
    this.errorDest2.push(destErrMsg);
   } else if (!this.validateDest(this.form.dest3)) {
    this.errorDest3.push(destErrMsg);
   } else if (!this.validateDest(this.form.dest4)) {
    this.errorDest4.push(destErrMsg);
   }
  },
  async onSubmit(msg) {
   let vip = 0;
   let onCall = 0;
   if (this.form.vip) {
    vip = 1;
   }
   if (this.form.onCall) {
    onCall = 1;
   }
   axios
    .post(
     `${this.$cookie.getCookie("API")}/api/v1/contacts?customerAccount=${
      this.account
     }&companyName=${this.form.companyName}&firstName=${this.form.fName}&lastName=${
      this.form.lName
     }&vip=${vip}&onCallDuty=${onCall}&code=${this.form.idCode}&contacts=${
      this.form.contactsNumber
     }&destination1=${this.form.dest1}&destination2=${this.form.dest2}&destination3=${
      this.form.dest3
     }&destination4=${this.form.dest4}`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    )
    .then((res) => {
     if (res) {
      this.companyName = "";
      this.idCode = "";
      this.fName = "";
      this.lName = "";
      this.contactsNumber = "";
      this.dest1 = "";
      this.dest2 = "";
      this.dest3 = "";
      this.dest4 = "";
      this.$emit("closeForm");
      window.scrollTo(0, 0);
      this.$emit("activeBanner");
      this.$emit("success", true);
      this.$emit("successMsg", msg);
     }
    })
    .catch((err) => {
     this.errorHandling(err);
     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", this.errorDataResponse);
    });
  },
  validateContacts(contacts) {
   let validRegEx = /^[0-9,]*$/;
   return contacts.match(validRegEx);
  },
  validateDest(dest) {
   let validRegEx = /^[0-9]*$/;
   if (dest === "") {
    return "ok";
   }
   return dest.match(validRegEx);
  },
 },
};
</script>

<style></style>
