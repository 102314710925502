<template>
 <div class="flex flex-col" v-if="this.closeForm">
  <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
   <div class="py-5 align-middle inline-block min-w-full sm:px-6 lg:px-10">
    <ContactForm
     @closeForm="this.$emit('closeForm')"
     @activeBanner="$emit('activeBanner')"
     @successMsg="$emit('successMsg', $event)"
     @success="$emit('success', $event)"
    />
   </div>
  </div>
 </div>

 <div>
  <div class="flex flex-col">
   <div class="-my-0 sm:-mx-6 lg:-mx-0">
    <div
     v-if="tbody?.length > 0 && !this.preview"
     class="align-middle inline-block min-w-full sm:px-6 lg:px-10"
    >
     <ContactTable
      :thead="thead"
      :tbody="tbody"
      :preview="preview"
      :data="[]"
      @sort="$emit('sort', $event)"
      @action="getData($event)"
      @action-detail="goToDetail($event)"
     />
    </div>
    <div v-else-if="this.preview" class="align-middle inline-block min-w-full sm:px-6 lg:px-10">
     <ContactTable
      :thead="thead"
      :tbody="previewData.elements"
      :data="[]"
      :preview="preview"
      :previewData="previewData"
      @action="getData($event)"
      @sort="$emit('sort', $event)"
      @activeBanner="$emit('activeBanner')"
      @successMsg="$emit('successMsg', $event)"
      @success="$emit('success', $event)"
     />
    </div>
    <div v-else class="justify-center pt-20">
     <EmptyState
      :title="$t('emptyStateTitle', { title: $t('contact') })"
      :subtitle="$t('emptyStateSubtitle', { title: $t('contact') })"
     />
    </div>
   </div>
  </div>
 </div>
</template>

<script>
import ContactTable from "../components/ContactTable.vue";

import ContactForm from "../components/ContactForm.vue";
import EmptyState from "../components/EmptyState.vue";

const apiUrl = process.env.VUE_APP_API;
const account = localStorage.getItem("account");

export default {
 name: "ContactBody",
 props: {
  data: {
   type: Array,
   required: true,
  },
  preview: {
   type: Array,
   required: false,
  },
  previewData: {
   type: Array,
   required: false,
  },
  thead: {
   type: Array,
   required: true,
  },
  tbody: {
   type: Array,
   required: true,
  },
  closeForm: {
   type: Boolean,
   required: true,
  },
 },
 components: {
  ContactTable,
  ContactForm,
  EmptyState,
 },
 data() {
  return {
   routes: [],
   account,
   apiUrl,
   previewTableHeader: ["COMPANY", "NAME", "VIP", "ON-CALL CONTRACT", "CODE", "CONTACT NUMBERS"],
   closeFormButton: false,
   currentSort: "id",
   currentSortDir: "asc",
   page: 1,
   rows: 10,
   selectedRows: null,
   selectRows: [5, 10, 15, 20, 25, 30],
  };
 },
 methods: {},
 mounted() {},
};
</script>

<style></style>
