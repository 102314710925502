<template>
 <div
  class="bg-gray-50 border border-gray-200 sm:items-center search_heading sticky top-0 flex flex-wrap justify-between"
 >
  <div class="">
   <div class="mt-3 sm:mt-0 sm:ml-0 flex-1">
    <SearchFilter
     v-model:search="search"
     @input="$emit('update:search', this.search)"
     :inputPlaceholder="$t('header.searchContactPlaceHolder')"
    />
   </div>
  </div>
 </div>

 <!-- <div class="col-start-1 col-end-2">
      <div class="relative flex-grow focus-within:z-10">
        <div
          class="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none"
        >
          <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          type="text"
          name="totalWTax"
          id="totalWTax"
          class="focus:encom_border_input  block lg:w-auto sm:w-20 md:w-20 sm:text-sm border-gray-300 h-auto pl-8 pr-0 mt-0"
          :placeholder="$t('contactsForm.searchCompany')"
          v-model="searchCompany"
          @input="$emit('update:searchCompany', $event.target.value)"
          :disabled="disableCompanySearch()"
        />
      </div>
    </div>
    <div class="col-start-2 col-end-3 mt-3 sm:mt-0">
      <div class="relative flex-grow focus-within:z-10">
        <div
          class="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none"
        >
          <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          type="text"
          name="number"
          id="number"
          class="focus:encom_border_input  block sm:w-20 md:w-20 lg:w-60 sm:text-sm border-gray-300 h-auto pr-0 pl-8 mt-0"
          :placeholder="$t('contactsForm.searchName')"
          v-model="searchName"
          @input="$emit('update:searchName', $event.target.value)"
          :disabled="disableNameSearch()"
        />
      </div>
    </div>
    <div class="col-start-5 col-end-6 mt-3 sm:mt-0">
      <div class="relative flex-grow focus-within:z-10">
        <div
          class="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none"
        >
          <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          type="text"
          name="number"
          id="number"
          class="focus:encom_border_input  block sm:w-20 md:w-20 lg:w-20 sm:text-sm border-gray-300 h-auto pr-0 pl-8 mt-0"
          :placeholder="$t('contactsForm.code')"
          v-model="searchCode"
          @input="$emit('update:searchCode', $event.target.value)"
          :disabled="disableCodeSearch()"
        />
      </div>
    </div>
    <div class="col-start-6 col-end-8">
      <div class="mt-3 sm:mt-0">
        <div class="relative flex-grow focus-within:z-10 mr-20">
          <div
            class="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none"
          >
            <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            type="text"
            name="totalNoTax"
            id="totalNoTax"
            class="focus:encom_border_input  block lg:w-40 sm:w-20 md:w-20 sm:text-sm border-gray-300 h-auto pl-8 pr-0 mt-0"
            :placeholder="$t('contactsForm.searchNumber')"
            v-model="searchContactNumb"
            @input="$emit('update:searchContactNumb', $event.target.value)"
            :disabled="disableNumberSearch()"
          />
        </div>
      </div>
    </div> -->
</template>

<script>
import { SearchIcon } from "@heroicons/vue/solid";
import SearchFilter from "../components/SearchFilter.vue";

export default {
 name: "ContactSearchHeader",
 components: {
  SearchIcon,
  SearchFilter,
 },
 data() {
  return {
   search: "",
  };
 },
 methods: {
  disableCompanySearch() {
   let disable = false;
   if (this.searchName || this.searchCode || this.searchContactNumb) {
    disable = true;
   }
   return disable;
  },
  disableNameSearch() {
   let disable = false;
   if (this.searchCompany || this.searchCode || this.searchContactNumb) {
    disable = true;
   }
   return disable;
  },
  disableCodeSearch() {
   let disable = false;
   if (this.searchName || this.searchCompany || this.searchContactNumb) {
    disable = true;
   }
   return disable;
  },
  disableNumberSearch() {
   let disable = false;
   if (this.searchName || this.searchCode || this.searchCompany) {
    disable = true;
   }
   return disable;
  },
 },
};
</script>

<style></style>
