<template>
 <PageHeading
  :title="$t('contacts')"
  :button-title="$t('contactsForm.addContact')"
  :has-search="true"
  :has-action-button="true"
  :has-meta-data="false"
  :first-button-title="
   userPermissions.permissions.includes('smart-routings.*') ||
   userPermissions.permissions.includes('smart-routings.edit')
    ? $t('importFile')
    : undefined
  "
  :second-button-title="
   userPermissions.permissions.includes('smart-routings.*') ||
   userPermissions.permissions.includes('smart-routings.edit')
    ? $t('export')
    : undefined
  "
  :last-button-title="
   userPermissions.permissions.includes('smart-routings.*') ||
   userPermissions.permissions.includes('smart-routings.delete')
    ? $t('deleteAll')
    : undefined
  "
  :has-permission="
   userPermissions.permissions.includes('smart-routings.*') ||
   userPermissions.permissions.includes('smart-routings.create')
  "
  @open="this.closeForm = !this.closeForm"
  @import="isPopoverOpen = !isPopoverOpen"
  @export="exportContacts()"
  @delete="
   deleteAll(
    $t('elementDeletedSuccessfully', { element: $t('contacts') }),
    $t('deleteAllContacts'),
    $t('deleteAllContactsDetail'),
    $t('delete'),
    $t('cancel')
   )
  "
  @update:search="$emit('update:search', $event)"
 />
 <div
  v-show="isPopoverOpen"
  class="max-w-7xl mx-auto bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
 >
  <div class="p-4 w-full">
   <div
    class="flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md hover:border-gray-500"
    @drop="dropHandler($event)"
    @dragover="dragOverHandler($event)"
   >
    <div class="space-y-1 text-center">
     <svg
      xmlns="http://www.w3.org/2000/svg"
      class="mx-auto h-12 w-12 text-gray-400"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      stroke-width="1"
     >
      <path
       stroke-linecap="round"
       stroke-linejoin="round"
       d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
      />
     </svg>
     <div class="flex justify-center text-sm text-gray-600">
      <label
       for="file-upload"
       class="relative cursor-pointer rounded-md font-medium text-main-color"
      >
       <span> {{ $t("uploadAFile") }}</span>
       <input
        id="file-upload"
        name="file-upload"
        type="file"
        ref="file"
        class="sr-only"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
        @change="handleFileUpload($event)"
       />
      </label>
      <p class="pl-1">{{ $t("or") }} {{ $t("dragAndDrop") }}</p>
     </div>

     <div class="flex justify-center">
      <ExclamationCircleIcon
       v-show="errorFormMandateUpload"
       class="h-4 w-4 text-red-500 mr-1"
       aria-hidden="true"
      />
      <p :class="errorFormMandateUpload ? 'text-xs text-red-600' : 'text-xs text-gray-500'">
       {{ $t("onlyFileAccepterd", { file: "excel" }) }}
      </p>
     </div>
     <p v-show="this.file.name" class="text-sm text-gray-700">
      <font-awesome-icon icon="file-import" class="h-4 w-4 text-gray-500" aria-hidden="true" />
      {{ this.file.name }}
     </p>
    </div>
   </div>
  </div>
  <button
   v-show="this.file"
   @click="submitFile('Please insert file')"
   type="button"
   class="max-w-7xl mx-auto uppercase rounded group body__header--button sm:text-sm lg:text-sm xl:text-sm md:text-sm text-white flex justify-end items-center text-sm font-medium shadow p-2"
  >
   {{ $t("upload") }}
  </button>
  <div class="rounded-md bg-blue-50 p-4 m-4">
   <div class="flex">
    <div class="flex-shrink-0">
     <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      class="h-5 w-5 text-blue-400"
     >
      <path
       fill-rule="evenodd"
       d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
       clip-rule="evenodd"
      />
     </svg>
    </div>
    <div class="ml-3 flex-1 md:flex md:justify-between">
     <p class="text-sm text-blue-700">
      {{ $t("importContactMsg") }}
     </p>
    </div>
   </div>
  </div>
 </div>

 <ContactBody
  :preview="this.preview"
  :previewData="this.previewData"
  :search="this.search"
  :closeForm="this.closeForm"
  :thead="thead"
  :tbody="filteredDatas()"
  :data="data"
  @sort="(this.currentSort = $event), sort($event)"
  @action-detail="(...args) => openDetail(...args)"
  @closeForm="this.closeForm = false"
  @activeBanner="$emit('activeBanner'), getDatas()"
  @successMsg="$emit('successMsg', $event)"
  @success="$emit('success', $event)"
 />

 <AskConfirmationDialog ref="askConfirmationDialog" />
</template>

<script>
import axios from "axios";
import ContactHeader from "../components/ContactHeader.vue";
import ContactSearchHeader from "../components/ContactSearchHeader.vue";
import ContactBody from "../components/ContactBody.vue";
import SuccessBanner from "../components/SuccessBanner.vue";
import PageHeading from "../components/PageHeading.vue";
import AskConfirmationDialog from "../components/AskConfirmationDialog.vue";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";

const account = localStorage.getItem("account");
const userPermissions = JSON.parse(localStorage.getItem("userPermissions"));

export default {
 props: ["search"],
 components: {
  ContactHeader,
  ContactSearchHeader,
  ContactBody,
  PageHeading,
  SuccessBanner,
  AskConfirmationDialog,
  Popover,
  PopoverButton,
  PopoverPanel,
 },
 data() {
  return {
   preview: false,
   previewData: [],
   account,
   currentSort: "id",
   currentSortDir: "desc",
   closeForm: false,
   isPopoverOpen: false,
   routes: [],
   thead: [
    { name: "company", sort: "companyName", isSort: false },
    { name: "fullName" /*, sort: "lastName"*/, isSort: false },
    { name: "isVip" /*, sort: "vip"*/, isSort: false },
    { name: "isOnCall" /*, sort: "onCallDuty"*/, isSort: false },
    { name: "code" /*, sort: "code"*/, isSort: false },
    { name: "callingNumbers" /*, sort: "contacts"*/ },
   ],
   tbody: [],
   file: "",
   key: false,
   userPermissions,
  };
 },
 methods: {
  setPreviewData(event) {
   this.previewData = event;
   this.preview = true;
   this.isPopoverOpen = false;
  },
  async getDatas() {
   this.$emit("setLoading", true);
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/contacts?customerAccount=${this.account}`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );
    this.tbody = res.data;
   } catch (error) {
    console.error(error);
   } finally {
    this.$emit("setLoading", false);
   }
  },
  sort(s) {
   if (s === this.currentSort) {
    this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
   }
   this.currentSort = s;
  },
  sortedDatas() {
   return this.tbody.sort((a, b) => {
    let toBeSorted = this.currentSort;
    let modifier = 1;
    if (this.currentSortDir === "desc") modifier = -1;

    if (a[toBeSorted] < b[toBeSorted]) return -1 * modifier;
    if (a[toBeSorted] > b[toBeSorted]) return 1 * modifier;

    return 0;
   });
  },
  filteredDatas() {
   return this.sortedDatas().filter((res) => {
    if (this.search) {
     const searchLower = this.search.toLowerCase();
     const fullName = (res.firstName ?? "") + (res.lastName ?? "");

     const companyNameMatch = res.companyName.toLowerCase().includes(searchLower);
     const contactsMatch = res.contacts.toLowerCase().includes(searchLower);
     const customerAccountMatch = res.customerAccount.toLowerCase().includes(searchLower);
     const destination1Match =
      res.destination1 && res.destination1.toLowerCase().includes(searchLower);
     const destination2Match =
      res.destination2 && res.destination2.toLowerCase().includes(searchLower);
     const destination3Match =
      res.destination3 && res.destination3.toLowerCase().includes(searchLower);
     const destination4Match =
      res.destination4 && res.destination4.toLowerCase().includes(searchLower);
     const firstNameMatch = res.firstName && res.firstName.toLowerCase().includes(searchLower);
     const lastNameMatch = res.lastName && res.lastName.toLowerCase().includes(searchLower);
     const fullNameMatch = fullName.toLowerCase().includes(searchLower.replace(/\s/g, ""));

     const createdAtMatch = this.$d(res.created_at, "shortText")
      .toLowerCase()
      .includes(searchLower.toLowerCase());
     const updatedAtMatch = this.$d(res.updated_at, "longText")
      .toLowerCase()
      .includes(searchLower.toLowerCase());

     return (
      companyNameMatch ||
      contactsMatch ||
      customerAccountMatch ||
      destination1Match ||
      destination2Match ||
      destination3Match ||
      destination4Match ||
      firstNameMatch ||
      lastNameMatch ||
      fullNameMatch ||
      createdAtMatch ||
      updatedAtMatch
     );
    }
    return res;
   });
  },

  dropHandler(ev) {
   console.log("File(s) dropped");

   // Prevent default behavior (Prevent file from being opened)
   ev.preventDefault();

   if (ev.dataTransfer.items) {
    // Use DataTransferItemList interface to access the file(s)
    for (var i = 0; i < ev.dataTransfer.items.length; i++) {
     // If dropped items aren't files, reject them
     const type = ev.dataTransfer.items[i].type;

     if (
      type === "multipart/form-data" ||
      type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
     ) {
      var file = ev.dataTransfer.items[i].getAsFile();
      this.file = file;
      this.errorFormMandateUpload = false;
     } else {
      this.errorFormMandateUpload = true;
     }
    }
   } else {
    // Use DataTransfer interface to access the file(s)
    for (var i = 0; i < ev.dataTransfer.files.length; i++) {
     console.log("... file[" + i + "].name = " + ev.dataTransfer.files[i].name);
    }
   }
  },
  dragOverHandler(ev) {
   // Prevent default behavior (Prevent file from being opened)
   ev.preventDefault();
  },
  handleFileUpload() {
   let type = this.$refs.file.files[0].type;

   if (
    type === "multipart/form-data" ||
    type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
   ) {
    this.file = this.$refs.file.files[0];
    this.errorFormMandateUpload = false;
   } else {
    this.errorFormMandateUpload = true;
   }
  },
  submitFile(noFileMsg) {
   /* Initialize the form data */
   let formData = new FormData();
   formData.append("file", this.file);
   /* Add the form data we need to submit */
   if (this.file) {
    this.$emit("setLoading", true);

    axios
     .post(
      `${this.$cookie.getCookie("API")}/api/v1/contacts/upload?customerAccount=${this.account}`,
      formData,
      {
       headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "multipart/form-data; boundary=---011000010111000001101001",
       },
      }
     )
     .then((res) => {
      this.$emit("setLoading", false);

      try {
       this.setPreviewData(res.data);
      } catch (error) {
       this.errorHandling(error);
      }
     })
     .catch((res) => {
      this.$emit("setLoading", false);
     });
    this.file = "";
   }

   /* Make the request to the POST /single-file URL  */
  },
  async exportContacts() {
   this.$emit("setLoading", true);

   const res = await axios
    .get(
     `${this.$cookie.getCookie("API")}/api/v1/contacts/export?customerAccount=${this.account}`,
     {
      headers: {
       "Content-Type": "application/json",
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
     }
    )
    .catch((err) => {
     this.$emit("setLoading", false);

     console.error(err);
    });
   const file = new Blob([await res.data], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
   });
   const fileURL = URL.createObjectURL(file);
   this.$emit("setLoading", false);

   window.open(fileURL);
  },
  async deleteAll(successMsg, title, confirmationMessage, confirmButton, goBack) {
   const r = await this.$refs.askConfirmationDialog.show({
    title: title,
    message: confirmationMessage,
    okButton: confirmButton,
    cancelButton: goBack,
    action: "delete",
   });
   if (r) {
    axios
     .delete(
      `${this.$cookie.getCookie("API")}/api/v1/contacts/all?customerAccount=${this.account}`,
      {
       headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
       },
      }
     )
     .then((res) => {
      if (res) {
       this.$emit("activeBanner");
       this.$emit("success", true);
       this.$emit("successMsg", successMsg);
       // this.sendMessage(successMsg);
       // window.alert(successMsg);
      }
     })
     .catch((error) => {
      this.errorHandling(error);
      this.$emit("activeBanner");
      this.$emit("success", false);
      this.$emit("successMsg", this.errorDataResponse);
     });
   }
  },
 },
 mounted() {
  this.getDatas();
 },
};
</script>

<style></style>
