<template>
 <div class="z-10 sticky top-0" :key="this.closeForm" v-if="!preview">
  <div
   :class="`flex flex-wrap justify-between body__header border-t border-b px-10 items-center py-2`"
  >
   <div class="flex gap-5">
    <button
     :class="[
      `rounded group body__header--button sm:text-sm lg:text-sm xl:text-sm md:text-sm text-white inline-flex items-center text-sm font-medium shadow p-2 mr-0`,
     ]"
     @click="this.sendOpenForm()"
    >
     <span class="uppercase">{{ $t("contactsForm.addContact") }}</span>
    </button>
    <div class="">
     <button
      @click="
       deleteAll(
        'Contact deleted successfully',
        'Delete contact',
        'Are you sure you want to delete the contact?',
        'Confirm',
        'Go back'
       )
      "
      type="button"
      class="uppercase rounded group bg-red-500 hover:bg-red-700 sm:text-sm lg:text-sm xl:text-sm md:text-sm text-white inline-flex items-center text-sm font-medium shadow p-2 mr-0"
     >
      {{ $t("deleteAll") }}
     </button>
    </div>
   </div>
   <div class="text-left flex gap-5">
    <Popover class="relative" v-slot="{ open }">
     <PopoverButton
      :class="[
       open ? 'text-gray-900' : 'text-gray-500',
       'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 capitalize',
      ]"
     >
      <span>{{ $t("importFile") }}</span>
      <ChevronDownIcon
       :class="[open ? 'text-gray-600' : 'text-gray-400', 'ml-2 h-5 w-5 group-hover:text-gray-500']"
       aria-hidden="true"
      />
     </PopoverButton>

     <transition
      enter-active-class="transition ease-out duration-200"
      enter-from-class="opacity-0 translate-y-1"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition ease-in duration-150"
      leave-from-class="opacity-100 translate-y-0"
      leave-to-class="opacity-0 translate-y-1"
     >
      <PopoverPanel class="absolute z-10 transform right-0 mt-3 px-2 max-w-md sm:px-0">
       <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
        <div class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
         <a
          class="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
         >
          <div class="">
           <!-- <div class="inline-flex ml-0 pb-1 pt-1 item-center">
                        <input
                          class="rounded text-center text-white pt-3 ml-0 w-auto items-center px-0 py-0 border border-transparent text-xs font-medium focus:outline-none"
                          type="file"
                          id="file"
                          ref="file"
                          v-on:change="handleFileUpload()"
                        />
                        <button
                          @click="submitFile('Please insert file')"
                          type="button"
                          :class="
                            `mt-1.5 uppercase rounded group body__header--button sm:text-sm lg:text-sm xl:text-sm md:text-sm text-white inline-flex items-center text-sm font-medium shadow p-2 encom_pink_bg:hover mr-0`
                          "
                        >
                          {{ $t("upload") }}
                        </button>
                      </div> -->
           <div
            class="w-80 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md hover:border-gray-500"
            @drop="dropHandler($event)"
            @dragover="dragOverHandler($event)"
           >
            <div class="space-y-1 text-center">
             <svg
              xmlns="http://www.w3.org/2000/svg"
              class="mx-auto h-12 w-12 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="1"
             >
              <path
               stroke-linecap="round"
               stroke-linejoin="round"
               d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
              />
             </svg>
             <div class="flex justify-center text-sm text-gray-600">
              <label
               for="file-upload"
               class="relative cursor-pointer rounded-md font-medium text-main-color"
              >
               <span> {{ $t("uploadAFile") }}</span>
               <input
                id="file-upload"
                name="file-upload"
                type="file"
                ref="file"
                class="sr-only"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                @change="handleFileUpload($event)"
               />
              </label>
              <p class="pl-1">{{ $t("or") }} {{ $t("dragAndDrop") }}</p>
             </div>
             <div class="flex justify-center">
              <ExclamationCircleIcon
               v-show="errorFormMandateUpload"
               class="h-4 w-4 text-red-500 mr-1"
               aria-hidden="true"
              />
              <p :class="errorFormMandateUpload ? 'text-xs text-red-600' : 'text-xs text-gray-500'">
               Only Excel files {{ $t("accepted") }}.
              </p>
             </div>
             <p v-show="this.file.name" class="text-sm text-gray-700">
              <font-awesome-icon
               icon="file-import"
               class="h-4 w-4 text-gray-500"
               aria-hidden="true"
              />
              {{ this.file.name }}
             </p>
            </div>
           </div>
          </div>
         </a>
        </div>
        <button
         v-show="this.file"
         @click="submitFile('Please insert file')"
         type="button"
         :class="`uppercase rounded group body__header--button sm:text-sm lg:text-sm xl:text-sm md:text-sm text-white flex justify-center items-center text-sm font-medium shadow p-2 w-full mr-0`"
        >
         {{ $t("upload") }}
        </button>
        <div class="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
         <div class="flow-root">
          <div
           class="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100 transition ease-in-out duration-150"
          >
           <InformationCircleIcon class="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
           <span class="ml-3">{{ $t("importContactMsg") }} </span>
          </div>
         </div>
        </div>
       </div>
      </PopoverPanel>
     </transition>
    </Popover>
    <button
     :class="[
      `rounded body__header--button sm:text-sm lg:text-sm xl:text-sm md:text-sm text-white inline-flex items-center text-sm font-medium shadow p-auto px-1 mr-0`,
     ]"
     @click.prevent="exportContacts"
    >
     <span class="uppercase">{{ $t("export") }}</span>
    </button>
   </div>
  </div>
  <!-- <transition
      enter-active-class="transition ease-out duration-200"
      enter-from-class="opacity-0 -translate-y-1"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition ease-in duration-150"
      leave-from-class="opacity-100 translate-y-0"
      leave-to-class="opacity-0 -translate-y-1"
    > -->
  <!-- Contact form -->
  <!-- </transition> -->
 </div>
 <ConfirmationDialog
  v-if="this.showMessage"
  :messageToShow="this.messageToShow"
  :subMessageToShow="this.subMessageToShow"
 />
 <ask-confirmation-dialog ref="askConfirmationDialog"></ask-confirmation-dialog>
 <loading
  v-model:active="isLoading"
  :can-cancel="false"
  :on-cancel="onCancel"
  :is-full-page="fullPage"
 />
</template>

<script>
import axios from "axios";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import ConfirmationDialog from "../components/ConfirmationDialog.vue";
import AskConfirmationDialog from "../components/AskConfirmationDialog.vue";

import { ChevronDownIcon } from "@heroicons/vue/solid";
import {
 InformationCircleIcon,
 CloudUploadIcon,
 ExclamationCircleIcon,
} from "@heroicons/vue/outline";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";

import { mapGetters } from "vuex";

export default {
 name: "ContactHeader",
 props: ["account"],
 components: {
  Popover,
  PopoverButton,
  ChevronDownIcon,
  ConfirmationDialog,
  AskConfirmationDialog,
  Loading,
  InformationCircleIcon,
  PopoverPanel,
  CloudUploadIcon,
  ExclamationCircleIcon,
 },
 data() {
  return {
   isLoading: false,
   showMessage: false,
   closeForm: true,
   file: "",
   errorFormMandateUpload: false,
  };
 },
 methods: {
  async exportContacts() {
   this.isLoading = true;
   const res = await axios
    .get(
     `${this.$cookie.getCookie("API")}/api/v1/contacts/export?customerAccount=${this.account}`,
     {
      headers: {
       "Content-Type": "application/json",

       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
     }
    )
    .catch((err) => {
     this.isLoading = false;
     console.error(err);
    });
   const file = new Blob([await res.data], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
   });
   const fileURL = URL.createObjectURL(file);
   this.isLoading = false;
   window.open(fileURL);
  },
  dropHandler(ev) {
   console.log("File(s) dropped");

   // Prevent default behavior (Prevent file from being opened)
   ev.preventDefault();

   if (ev.dataTransfer.items) {
    // Use DataTransferItemList interface to access the file(s)
    for (var i = 0; i < ev.dataTransfer.items.length; i++) {
     // If dropped items aren't files, reject them
     const type = ev.dataTransfer.items[i].type;

     if (
      type === "multipart/form-data" ||
      type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
     ) {
      var file = ev.dataTransfer.items[i].getAsFile();
      this.file = file;
      this.errorFormMandateUpload = false;
     } else {
      this.errorFormMandateUpload = true;
     }
    }
   } else {
    // Use DataTransfer interface to access the file(s)
    for (var i = 0; i < ev.dataTransfer.files.length; i++) {
     console.log("... file[" + i + "].name = " + ev.dataTransfer.files[i].name);
    }
   }
  },
  dragOverHandler(ev) {
   // Prevent default behavior (Prevent file from being opened)
   ev.preventDefault();
  },
  sendOpenForm() {
   this.closeForm = !this.closeForm;
   document.body.scrollTop = 0;
   document.documentElement.scrollTop = 0;
  },
  handleFileUpload() {
   let type = this.$refs.file.files[0].type;
   console.log("file: ContactHeader.vue ~ line 344 ~ handleFileUpload ~ type", type);
   if (
    type === "multipart/form-data" ||
    type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
   ) {
    this.file = this.$refs.file.files[0];
    this.errorFormMandateUpload = false;
   } else {
    this.errorFormMandateUpload = true;
   }
  },
  submitFile(noFileMsg) {
   /* Initialize the form data */
   let formData = new FormData();
   formData.append("file", this.file);
   /* Add the form data we need to submit */
   if (this.file) {
    this.isLoading = true;
    axios
     .post(
      `${this.$cookie.getCookie("API")}/api/v1/contacts/upload?customerAccount=${this.account}`,
      formData,
      {
       headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "multipart/form-data; boundary=---011000010111000001101001",
       },
      }
     )
     .then((res) => {
      this.isLoading = false;
      try {
       this.$emit("previewData", res.data);
      } catch (error) {
       this.errorHandling(error);
      }
      // this.preview = true;
      // this.previewData = res.data;
     })
     .catch((res) => {
      this.isLoading = false;
     });
   } else {
    this.sendMessage(noFileMsg);
   }

   /* Make the request to the POST /single-file URL  */
  },
  async deleteAll(successMsg, title, confirmationMessage, confirmButton, goBack) {
   const r = await this.$refs.askConfirmationDialog.show({
    title: title,
    message: confirmationMessage,
    okButton: confirmButton,
    cancelButton: goBack,
    action: "delete",
   });
   if (r) {
    axios
     .delete(
      `${this.$cookie.getCookie("API")}/api/v1/contacts/all?customerAccount=${this.account}`,
      {
       headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
       },
      }
     )
     .then((res) => {
      if (res) {
       this.$emit("activeBanner");
       this.$emit("success", true);
       this.$emit("successMsg", successMsg);
       // this.sendMessage(successMsg);
       // window.alert(successMsg);
      }
     })
     .catch((error) => {
      this.errorHandling(error);
      this.$emit("activeBanner");
      this.$emit("success", false);
      this.$emit("successMsg", this.errorDataResponse);
     });
   }
  },
  sendMessage(msg) {
   this.showMessage = true;
   this.messageToShow = msg;
  },
 },

 watch: {
  closeForm: function (val) {
   this.$emit("closeForm", val);
  },
 },
};
</script>

<style></style>
